import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import ApproveCompanyTable from './components/approve-company-table'

const ApproveCompany = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
     <ApproveCompanyTable />
    </Layout>
  </div>
  )
}

export default ApproveCompany